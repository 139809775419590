// Our main CSS
import '../css/app.css'

/**
 * Vue is ready for you, if you need it.
 */

/**
 * The following block of code automatically registers your Vue components.
 * It will recursively scan this directory for the Vue components and
 * automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 *
 * Feel free to remove this block if you don't like black magic fuckery,
 * or you want to register your Vue components manually.
 */
/*
 *** https://v3.vuejs.org/
 */
// interface ImportedModule {
//   name: string
//   default: object
// }
// const vueSingleFileComponents = import.meta.glob<ImportedModule>('./**/*.vue', {
//   eager: true
// })

import { createApp } from 'vue'


/**
 * 🦄: Now do your magic.
 */
document.addEventListener('DOMContentLoaded', function() {
    const video = document.getElementById('menu-logo');

    video.addEventListener('mouseenter', function() {
        video.currentTime = 0; // Rewind to the start
        video.play(); // Play the video
    });

});

const menu = createApp({

  data: () => ({
      active: null,
  }),

  watch: {
      active: {
          handler(newValue) {
              if (newValue === null) {
                  return document.body.classList.remove('overflow-hidden', 'overlay');
              }
              return document.body.classList.add('overflow-hidden')
          },
          immediate: true
      }
  },

  methods: {
      toggle(name) {
          if (this.active === name) {
              this.subMenuActive = null
              return this.active = null
          }

          this.active = name
      },

      handleScroll() {
          const menuHolder = this.$refs.menuHolder;
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

          if (scrollTop > 2) {
              //menuHolder.classList.add('bg-white');
          } else {
              //menuHolder.classList.remove('bg-white');
          }

      },
  },
  mounted() {
      window.addEventListener('scroll', this.handleScroll);
  },
})

menu.mount('#main-menu')

console.log(menu)